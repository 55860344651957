<template>
  <div class="contact-container">
    <h1>联系方式</h1>

    <p>公司地址： 广州市天河区科韵北路创锦产业园106号楼204</p>
    <p>邮编：510640</p>
    <p>电话：020-89289384</p>
    <p>邮箱：vesunai@vesunai.com</p>  
  </div>
</template>

<script>
export default {
  name: "ContactPage",
  data() {
    return {
      phoneNumber: "123-456-7890", // 替换为实际的联系电话
      address: "某省某市某区某街道123号", // 替换为实际的地址
      // 注意：如果使用百度地图JavaScript API，则不需要在data中存储地图的URL
    };
  },
  // 如果使用百度地图JavaScript API，则可以在mounted钩子中初始化地图
  mounted() {
    // 这里是初始化百度地图JavaScript API的代码（如果需要的话）
    // 例如：
    // var map = new BMap.Map("mapContainer"); // mapContainer是地图容器的id
    // var point = new BMap.Point(经度, 纬度); // 替换为实际的经纬度
    // map.centerAndZoom(point, 15); // 初始化地图,设置中心点坐标和地图级别
    // map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
    // ... 其他地图配置
  },
  // 注意：如果不需要使用百度地图的交互功能，上面的mounted钩子和相关注释可以删除
};
</script>

<style scoped>
.contact-container {
  padding: 20px;
}

.map-container {
  margin-top: 20px;
  border: 1px solid #ccc;
}

/* 如果使用百度地图JavaScript API并需要自定义样式，可以在这里添加额外的CSS */
</style>
