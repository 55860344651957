<!-- src/App.vue -->  
<template>  
  <div id="app">  
    <WsNavbar />  
    <router-view/>  
    <foot-page/>
  </div>  
</template>  
  
<script>  
import WsNavbar from './components/WsNavbar.vue'  
import FootPage from './views/FootPage.vue';
  
export default {  
  name: 'App',  
  components: {  
    WsNavbar,
    FootPage
  }  
}  
</script>  
  
<style>  
#app {  
  font-family: Avenir, Helvetica, Arial, sans-serif;  
  -webkit-font-smoothing: antialiased;  
  -moz-osx-font-smoothing: grayscale;  
  text-align: center;  
  color: #2c3e50;  
  margin-top: 30px;  
}  

 
</style>