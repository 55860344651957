<template>  
    <footer class="foot-page">  
      <div class="container">  
        
        <div class="copyright">  

            <p>Copyright 2016-  {{ currentYear }} 广州微声技术有限公司 版权所有  <a href="http://beian.miit.gov.cn" rel="nofollow" target="_blank">粤ICP备18128834号</a> </p>	
<p>地址：广州市天河区科韵北路创锦产业园106号楼204</p>
<p>联系电话：020-89289384     </p>
 <p>邮箱：vesunai@vesunai.com   </p>
 
        </div>  
      </div>  
    </footer>  
  </template>  
    
  <script>  
  export default {  
    name: 'FootPage',  
    data() {  
      return {  
        currentYear: new Date().getFullYear(),  
      };  
    },  
  };  
  </script>  
    
  <style scoped>  
  .foot-page {  
    background-color: #f8f9fa;  
    padding: 20px 0;  
    text-align: center;  
  }  
    
  .container {  
    max-width: 1200px;  
    margin: 0 auto;  
    padding: 0 15px;  
  }  
    
  .links {  
    margin-bottom: 20px;  
  }  
    
  .link {  
    color: #343a40;  
    text-decoration: none;  
    margin: 0 10px;  
    transition: color 0.3s;  
  }  
    
  .link:hover {  
    color: #007bff;  
  }  
    
  .social-media {  
    margin-bottom: 20px;  
  }  
    
  .social-link {  
    margin: 0 5px;  
  }  
    
  .social-link img {  
    width: 30px;  
    height: 30px;  
  }  
    
  .copyright {  
    font-size: 14px;  
    color: #6c757d;  
  }  
  </style>