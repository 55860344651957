<!-- src/views/HomePage.vue -->
<template>
  <div class="home">
    <img src="img/homebg.jpg" />
    <h1>广州微声技术有限公司</h1>
    <p></p>

    <p>解决方案</p>
    <div class="cotent">
      <p>
        &nbsp;&nbsp;公司核心由一群有着智能语音创新激情，
        致力于在语音领域做出革新和突玻的资深研究团队组成。
       公司开发的智能快审系统以语音处理技术为基础，凭借互联网经验技术及知识，打造
        快审资料快速生产、资料云综合平台应用等法院智慧庭审整体解决方案，
        为推动法院“繁简分流、简案快审“提供创新力量。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style scoped>
.home {
  text-align: center;
  padding: 20px;
}

.home img {
  width: 100%;
  height: auto;
}

.home .cotent{
  text-align: left;
}
</style>
