<template>  
    <div class="about-page">  
        <img src="img/headbg.jpg">
      <h1>关于我们</h1>  
      <p> &nbsp;&nbsp; 广州微声技术有限公司坚持以持续技术创新为客户不断创造价值，以 做“技术创新型”企业为定位。公司核心由一群有着智能语音创新激情，致力于在语音领域做出革新和突玻的资深研究团队组成。公司开发的智 能快审系统以语音处理技术为基础，凭借互联网经验技术及知识，打造 快审资料快速生产、资料云综合平台应用等法院智慧庭审整体解决方案，为推动法院“繁简分流、简案快审“提供创新力量。
  </p>  
  <p>&nbsp;&nbsp; 未来发展，公司将一如既往地坚持“创新坚守分享“的企业核心价值观，努力实现“科技改善生活“的企业使命，力争发展成为行业服务内容最广、最有价值、最具竟争力的智能语音技术服务与方案提供商。</p>
       
    </div>  
  </template>  
    
  <script>  
  export default {  
    name: 'AboutPage', // 注意：按照最佳实践，组件名称应该是多单词的，但这里为了示例简单性而使用单词  
    // 你可以在这里定义组件的数据、计算属性、方法等  
    data() {  
      return {  
        // 可以在这里定义组件的状态  
      };  
    },  
    methods: {  
      // 可以在这里定义组件的方法  
    },  
    // 你可以在这里添加更多的 Vue 生命周期钩子、计算属性、观察者等  
  };  
  </script>  
    
  <style scoped>  
  .about-page {  
    padding: 5px;  
    text-align: left;
    /* 你可以在这里添加更多的样式 */  
  }  
    
  .about-page h1 {  
    color: #333;  
    /* 你可以为 h1 标签定义特定的样式 */  
  }  
    
  .about-page p {  
    font-size: 16px;  
    color: #666;  
    
    /* 你可以为 p 标签定义特定的样式 */  
  }  

  .about-page  img{  
    height: auto;
    width: 100%;
    /* 你可以为 p 标签定义特定的样式 */  
  }  
  </style>