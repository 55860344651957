<!-- src/components/Navbar.vue -->  
<template>  
    <nav>  
      <img src="../assets/logo.png">
      <ul>   
        <li><router-link to="/">主页</router-link></li>  
        <li><router-link to="/about">关于我们</router-link></li>    
        <li><router-link to="/contact">联系我们</router-link></li>  
      </ul>  
    </nav>  
  </template>  
    
  <script>  
  export default {  
    name: 'WsNavbar'  ,
    mounted(){
      document.title = '广州微声技术有限公司';
    }
  }  
  </script>  
    
  <style scoped>  
  nav {  
    background-color: #f8f9fa;  
    padding: 10px;  
  }  

  img{  
    float: left;
    height: 55px;
  }  
    
  nav ul {  
    display: flex; /* 使用flexbox布局 */  
    justify-content: right; 

    list-style: none;  
    padding: 0;  
    margin: 0;   
  }  

 

  ul li {  
    padding: 10px;   
  }  
    
  nav ul li a {  
    margin: 0 15px;   
    color: #343a40;  
    text-decoration: none;  
    margin: 0 10px;  
    transition: color 0.3s;   
    /* text-decoration: none;  */
  }  
     
  nav ul li a:hover {  
    color: #007bff;  
  }  
    
  nav ul li a.router-link-exact-active {  
    font-weight: bold;  
  }  
  </style>